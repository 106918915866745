import './ContactDetails.css';

export default () => {
    const emailLink = <a
        href="mailto:noel.whitaker0@gmail.com"
        className="contactLink"
    >
        noel.whitaker0@gmail.com
    </a>
    const linkedinLink = <a
        href="https://www.linkedin.com/in/noel-whitaker"
        target="_blank"
        rel="noopener noreferrer"
        className="contactLink"
    >
        linkedin.com/in/noel-whitaker
    </a>
    return <div className="contactDetails">{emailLink}{linkedinLink}</div>;
}