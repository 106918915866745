import './AboutMe.css';

export default () => {
    const header = <h className="aboutHeader">About Me</h>;
    const line1 = <p className="aboutText">I'm a full-stack engineer who loves golden retrievers.</p>;
    const line2 = <p className="aboutText">Currently working and living in NYC.</p>;
    const line3= <p className="aboutText">I enjoy brewing fresh coffee, building keyboards and listening to audiobooks.</p>;
    return (
        <div>
        {header}
        {line1}
        {line2}
        {line3}
        </div>
    );
};